import revive_payload_client_6Bc3JBCBSA from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.50_sass@1.62.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2e5Oqadjzd from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.50_sass@1.62.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cog4iMHJnU from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.50_sass@1.62.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_aehmylPJeH from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.4.1_@vue+compiler-core@3.5.3_nuxt@3.7.4_postcss@8.4.4_dc3roiqpzsqic4fohmeh6yokhq/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_pEuD5fCAXp from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.50_sass@1.62.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_ZpQKErNmOO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.50_sass@1.62.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_mpQxHopD2i from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.5.3/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.mjs";
import plugin_UhT6Zionie from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.5.3/node_modules/@nuxtjs/prismic/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/opt/build/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_IrCjpet9oq from "/opt/build/repo/node_modules/.pnpm/nuxt-primevue@0.3.1_vue@3.5.3/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_7cfJlJE4OB from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.50_sass@1.62.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_client_mtqVw3YRXk from "/opt/build/repo/plugins/analytics.client.ts";
import emitter_eaMgM0oZTb from "/opt/build/repo/plugins/emitter.ts";
import scroll_client_HJUo2UKx9n from "/opt/build/repo/plugins/scroll.client.ts";
export default [
  revive_payload_client_6Bc3JBCBSA,
  unhead_2e5Oqadjzd,
  router_cog4iMHJnU,
  _0_siteConfig_aehmylPJeH,
  payload_client_pEuD5fCAXp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZpQKErNmOO,
  plugin_client_mpQxHopD2i,
  plugin_UhT6Zionie,
  primevue_plugin_egKpok8Auk,
  plugin_client_IrCjpet9oq,
  chunk_reload_client_7cfJlJE4OB,
  analytics_client_mtqVw3YRXk,
  emitter_eaMgM0oZTb,
  scroll_client_HJUo2UKx9n
]