import { default as previewLA4wNmrmcAMeta } from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.5.3/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue?macro=true";
import { default as a_45proposIIXjlPC1SAMeta } from "/opt/build/repo/pages/a-propos.vue?macro=true";
import { default as avis_45clientsQWdzKa2A0TMeta } from "/opt/build/repo/pages/avis-clients.vue?macro=true";
import { default as _91uid_93S6twjIoFImMeta } from "/opt/build/repo/pages/blog/[uid].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as data_45protectionUcYkTzlGIPMeta } from "/opt/build/repo/pages/data-protection.vue?macro=true";
import { default as devis_45rgpdXlHmQuOGG2Meta } from "/opt/build/repo/pages/devis-rgpd.vue?macro=true";
import { default as entreprisesZOJkMteCtFMeta } from "/opt/build/repo/pages/entreprises.vue?macro=true";
import { default as formationsLHxTE7ZTYQMeta } from "/opt/build/repo/pages/formations.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as medico_45socialHj65A4AMSjMeta } from "/opt/build/repo/pages/medico-social.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as succeedwoOfuswS9lMeta } from "/opt/build/repo/pages/succeed.vue?macro=true";
import { default as verifyPPsbf6HYMxMeta } from "/opt/build/repo/pages/verify.vue?macro=true";
export default [
  {
    name: previewLA4wNmrmcAMeta?.name ?? "prismic-preview",
    path: previewLA4wNmrmcAMeta?.path ?? "/blog/preview/",
    meta: previewLA4wNmrmcAMeta || {},
    alias: previewLA4wNmrmcAMeta?.alias || [],
    redirect: previewLA4wNmrmcAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.5.3/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue").then(m => m.default || m)
  },
  {
    name: a_45proposIIXjlPC1SAMeta?.name ?? "a-propos",
    path: a_45proposIIXjlPC1SAMeta?.path ?? "/a-propos",
    meta: a_45proposIIXjlPC1SAMeta || {},
    alias: a_45proposIIXjlPC1SAMeta?.alias || [],
    redirect: a_45proposIIXjlPC1SAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/a-propos.vue").then(m => m.default || m)
  },
  {
    name: avis_45clientsQWdzKa2A0TMeta?.name ?? "avis-clients",
    path: avis_45clientsQWdzKa2A0TMeta?.path ?? "/avis-clients",
    meta: avis_45clientsQWdzKa2A0TMeta || {},
    alias: avis_45clientsQWdzKa2A0TMeta?.alias || [],
    redirect: avis_45clientsQWdzKa2A0TMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/avis-clients.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93S6twjIoFImMeta?.name ?? "blog-uid",
    path: _91uid_93S6twjIoFImMeta?.path ?? "/blog/:uid()",
    meta: _91uid_93S6twjIoFImMeta || {},
    alias: _91uid_93S6twjIoFImMeta?.alias || [],
    redirect: _91uid_93S6twjIoFImMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog",
    path: indexmFsYN4t0XfMeta?.path ?? "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: data_45protectionUcYkTzlGIPMeta?.name ?? "data-protection",
    path: data_45protectionUcYkTzlGIPMeta?.path ?? "/data-protection",
    meta: data_45protectionUcYkTzlGIPMeta || {},
    alias: data_45protectionUcYkTzlGIPMeta?.alias || [],
    redirect: data_45protectionUcYkTzlGIPMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/data-protection.vue").then(m => m.default || m)
  },
  {
    name: devis_45rgpdXlHmQuOGG2Meta?.name ?? "devis-rgpd",
    path: devis_45rgpdXlHmQuOGG2Meta?.path ?? "/devis-rgpd",
    meta: devis_45rgpdXlHmQuOGG2Meta || {},
    alias: devis_45rgpdXlHmQuOGG2Meta?.alias || [],
    redirect: devis_45rgpdXlHmQuOGG2Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/devis-rgpd.vue").then(m => m.default || m)
  },
  {
    name: entreprisesZOJkMteCtFMeta?.name ?? "entreprises",
    path: entreprisesZOJkMteCtFMeta?.path ?? "/entreprises",
    meta: entreprisesZOJkMteCtFMeta || {},
    alias: entreprisesZOJkMteCtFMeta?.alias || [],
    redirect: entreprisesZOJkMteCtFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/entreprises.vue").then(m => m.default || m)
  },
  {
    name: formationsLHxTE7ZTYQMeta?.name ?? "formations",
    path: formationsLHxTE7ZTYQMeta?.path ?? "/formations",
    meta: formationsLHxTE7ZTYQMeta || {},
    alias: formationsLHxTE7ZTYQMeta?.alias || [],
    redirect: formationsLHxTE7ZTYQMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/formations.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: medico_45socialHj65A4AMSjMeta?.name ?? "medico-social",
    path: medico_45socialHj65A4AMSjMeta?.path ?? "/medico-social",
    meta: medico_45socialHj65A4AMSjMeta || {},
    alias: medico_45socialHj65A4AMSjMeta?.alias || [],
    redirect: medico_45socialHj65A4AMSjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/medico-social.vue").then(m => m.default || m)
  },
  {
    name: privacyjCuzG6sq6pMeta?.name ?? "privacy",
    path: privacyjCuzG6sq6pMeta?.path ?? "/privacy",
    meta: privacyjCuzG6sq6pMeta || {},
    alias: privacyjCuzG6sq6pMeta?.alias || [],
    redirect: privacyjCuzG6sq6pMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: succeedwoOfuswS9lMeta?.name ?? "succeed",
    path: succeedwoOfuswS9lMeta?.path ?? "/succeed",
    meta: succeedwoOfuswS9lMeta || {},
    alias: succeedwoOfuswS9lMeta?.alias || [],
    redirect: succeedwoOfuswS9lMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/succeed.vue").then(m => m.default || m)
  },
  {
    name: verifyPPsbf6HYMxMeta?.name ?? "verify",
    path: verifyPPsbf6HYMxMeta?.path ?? "/verify",
    meta: verifyPPsbf6HYMxMeta || {},
    alias: verifyPPsbf6HYMxMeta?.alias || [],
    redirect: verifyPPsbf6HYMxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/verify.vue").then(m => m.default || m)
  }
]